<template>
  <div
    class="product-slider-wrapper-container"
    v-intersect.once="updateSliderWrapperViewable"
  >
    <transition enter-active-class="animate__animated animate__fadeIn">
      <div
        class="product-slider-wrapper"
        v-if="defaultJsonConfig && (!counter || (counter && id < counter))"
      >
        <ProductSlider :config="defaultJsonConfig" :container="container" />
      </div>
    </transition>
  </div>
</template>
<style lang="scss">
.animate__animated.animate__fadeIn {
  transition: 1s;
  animation-delay: 0.5s;
}
</style>
<script>
import ProductSlider from "@/components/categoryBlock/ProductSlider.vue";

import merge from "lodash/merge";

export default {
  name: "ProductSliderWrapper",
  components: {
    ProductSlider
  },
  props: {
    container: { type: Boolean, default: true },
    query: { type: String, required: false },
    id: { type: Number, default: Math.floor(Math.random() * 100000000) },
    counter: { type: Number, required: false },
    title: { type: String, required: false },
    hideLink: { type: Boolean, default: false },
    description: { type: String, required: false },
    layout: { type: Number, required: false },
    warehouseId: { type: Number, required: false },
    timeslotId: { type: String, required: false },
    productId: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    promo: { type: Boolean, required: false, default: false },
    category: { type: Object, default: () => {} },
    subCategory: { type: Object, required: false },
    productLimit: { type: Number, required: false, default: 12 },
    productSliderCard: { type: Object, default: () => {} },
    swiperOption: { type: Object, required: false },
    showArrows: { type: Boolean, default: false },
    showBullets: { type: Boolean, default: true },

    // productSliderCard
    productSliderCardShowCard: { type: Boolean, default: false },
    productSliderCardButtonText: { type: String, required: false },
    productSliderCardCardText: { type: String, required: false },
    productSliderCardCardImage: { type: Object, required: false },
    productSliderCardCardBgColor: {
      type: String,
      default: "var(--v-white-base)"
    }
    // productSliderCard end

    // categoryPath: { type: String, required: false },
    // productList: { type: Array, required: false },
    // query: { type: String, required: false },

    // description: { type: String, required: false },
    // proposal: { type: Object, required: false },
    // userId: { type: Number, required: false },
  },
  data() {
    return {
      defaultJsonConfig: null
    };
  },
  computed: {
    mergedSwiperOptions() {
      return merge(
        {
          slidesPerGroup: 1,
          spaceBetween: 20,
          watchOverflow: true,
          autoplay: false,
          breakpoints: {
            xs: { slidesPerView: 1 },
            sm: { slidesPerView: 2 },
            md: { slidesPerView: 3 },
            lg: { slidesPerView: 3 },
            xl: { slidesPerView: 4 }
          }
        },
        this.swiperOption
      );
    }
  },
  methods: {
    updateSliderWrapperViewable() {
      this.$emit("updateSliderWrapperViewable", this.id + 2);
      console.log("updateSliderWrapperViewable", this.id + 2);
    },
    buildConfig() {
      this.defaultJsonConfig = {
        categoryBlockId: this.id,
        templateBlock: {
          codInt: `position1${this.id}`
        },
        templateBlockType: {
          templateBlockTypeId: 3,
          modeName: "ProductSlider"
        },
        priority: 1,
        metaData: {
          categoryblocktype_ProductSlider: {
            TITLE: this.title,
            DESCRIPTION: this.description,
            HIDE_LINK: this.hideLink,
            QUERY: this.query,
            CATEGORY: this.category
              ? {
                  categoryId: this.category.categoryId,
                  name: this.category.name,
                  slug: this.category.slug,
                  categoryTypeId: this.category.categoryTypeId,
                  navigationTypeId: this.category.navigationTypeId,
                  template: this.category.template
                }
              : {},
            SUB_CATEGORY: this.subCategory,
            PRODUCT_LIMIT: this.productLimit,
            BUTTON_TEXT: this.productSliderCardButtonText, //default: this.$t("categoryBlock.productListSlider.showAll")
            SHOW_CARD: this.productSliderCardShowCard,
            CARD_TEXT: this.productSliderCardCardText,
            CARD_IMAGE: this.productSliderCardCardImage, // example card image -> {description: "novita",alt: "novita",location:"https://www.cooptrentino.it/photo/2023/06/12/3/generic-format/raw/novita.png"},
            CARD_BG_COLOR: this.productSliderCardCardBgColor,
            JSON_CONFIG: this.mergedSwiperOptions,
            SHOW_ARROWS: this.showArrows,
            SHOW_BULLETS: this.showBullets,
            LAYOUT: this.layout,
            WAREHOUSE_ID: this.warehouseId,
            TIMESLOT_ID: this.timeslotId,
            PRODUCT_ID: this.productId,
            SHUFFLE: this.shuffle,
            PROMO: this.promo
          }
        }
      };
    }
  },
  mounted() {
    this.buildConfig();
  }
};
</script>
